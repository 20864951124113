<template>
  <div class="userLogin">
	  <common-head url="/tesetPassword" :is-return="true"></common-head>
	  
	  <div class="layoutBox bgWhite">
		  <div class="caption">{{ $t('tba.EnterReceivedVerificationCode') }}</div>
		  <p>{{ $t('tba.codeHasSend') }} <span class="mainColor1">{{ setNumber(account) }}</span></p>
		  
		  <div class="bodyStyleDivStyle pc_in">
		    <div class="divbox">
		      <span
		        class="g_hx"
		        :class="{ bl: index < phone.length }"
		        v-for="(item, index) in 6"
		        :key="item"
		      ></span>
		    </div>
		    <p>
		      <input
		        v-model="phone"
		        maxLength="6"
		        class="body1StyleInputStyle"
		        type="text"
		      />
		    </p>
		  </div>
		  
	  </div>
	  
    <new-title url="/" title="" />
    <div class="body1Style">
      <div class="pageTitle">
        输入收到的验证码
        <p class="text-1">
          验证码已发送至：
          <template v-if="loginType === 'number'">+{{ code }}</template>
          <span class="mainColor1">{{ setNumber(account) }}</span>
        </p>
      </div>

      <div class="bodyStyleDivStyle pc_in">
        <div class="divbox">
          <span
            class="g_hx"
            :class="{ bl: index < phone.length }"
            v-for="(item, index) in 6"
            :key="item"
          ></span>
        </div>
        <p>
          <input
            v-model="phone"
            maxLength="6"
            class="body1StyleInputStyle"
            type="text"
          />
        </p>
      </div>
    </div>
    <div class="bottom-ve">
      <van-row type="flex" justify="space-around">
        <van-col span="24">
          <van-button
            class="btnStyle mainBackColor1"
            @click="getSms"
            type="primary"
            :disabled="flag"
          >
            重新发送
            <span v-if="flag">({{ count }})</span>
          </van-button>
        </van-col>
      </van-row>
    </div>
  </div>
</template>
<script>
import NewTitle from "../../components/title";

export default {
  name: "verificationCode",
  components: { NewTitle },
  data() {
    return {
      sms: "", // 短信验证码
      flag: false, // 获取验证码 是否禁用
      count: 60, // 60s 倒计时
      timer: null, // 倒计时的 定时器
      sid: "", // 验证
      openid: "",
      phone: "",
      password: "", // 密码
      isShowPwd: false, // 是否显示密码
      isSubmit: false,
      value1: "86",
      refcode: this.$route.query.refcode,
      type: this.$route.query.type,
      date: this.$route.query.date,
    };
  },
  created() {
    if (this.date) {
      const newDate = Date.parse(new Date());
      if (newDate - Number(this.date) < 60000)
        this.countdown(60 - (newDate - Number(this.date)) / 1000);
    }
    this.sid = this.$route.query.sid;
  },
  watch: {
    phone(val) {
      if (val && val.length === 6) {
        this.$router.push({
          path: "/setPassword",
          query: {
            account: this.account,
            sms: val,
            loginType: this.loginType,
            type: this.type,
            code: this.value1,
            sid: this.sid,
            refcode: this.refcode,
          },
        });
      }
    },
  },
  computed: {
    loginType() {
      if (this.$route.query && this.$route.query.loginType) {
        return this.$route.query.loginType;
      }
      return null;
    },
    account() {
      if (this.$route.query && this.$route.query.account) {
        return this.$route.query.account;
      }
      return null;
    },
    code() {
      if (this.$route.query && this.$route.query.code) {
        return this.$route.query.code;
      }
      return null;
    },
    type() {
      if (this.$route.query && this.$route.query.type) {
        return this.$route.query.type;
      }
      return null;
    },
  },
  methods: {
    setNumber(str) {
      if (this.loginType === "email") {
        return this.noPassByEmail(str);
      } else {
        if (str) {
          const pat = /(\d{3})\d*(\d{4})/;
          const val = str.replace(pat, "$1****$2");
          return val;
        }
        return;
      }
    },
    noPassByEmail(email) {
      let new_email = email;
      if (String(email).indexOf("@") > 0) {
        let str = email.split("@");
        let _s = "";
        if (str[0].length > 3) {
          //@前面多于3位
          for (let i = 3; i < str[0].length; i++) {
            _s += "*";
          }
          new_email = str[0].substr(0, 3) + _s + "@" + str[1];
        } else {
          //@前面小于等于于3位
          for (let i = 1; i < str[0].length; i++) {
            _s += "*";
          }
          new_email = str[0].substr(0, 1) + _s + "@" + str[1];
        }
      }
      return new_email;
    },
    // onClickLeft  点击返回“设置”页面
    onClickLeft() {
      this.$router.push("uSet");
    },
    // 提示
    showAlert(msg) {
      return this.$dialog.alert({
        title: "提示",
        message: msg,
      });
    },
    // 验证
    check() {
      this.account.trim(); // 去除一下两端空格
      return true;
    },
    // 点击 获取验证码
    getSms() {
      const TIME_COUNT = 60;
      if (this.flag == false) {
        let url = "/security/index/phonecode";
        let parameter = {
          phone: this.account,
		  nation: this.value1
        };
        if (this.loginType === "email") {
          url = "/security/index/emailcode";
          parameter = {
            email: this.account,
          };
        }
        this.$post2(url, parameter)
          .then((res) => {
            this.sid = res.sid;
            this.$toast.success("验证码发送成功");
            if (!this.timer) {
              this.dateTwo = Date.parse(new Date());
              this.countdown(TIME_COUNT);
            }
          })
          .catch((e) => {});
      }
    },
    countdown(TIME_COUNT) {
      this.count = TIME_COUNT;
      this.flag = true;
      this.timer = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
          this.count--;
        } else {
          this.flag = false;
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 1000);
    },
    // 注册
    getCode() {
      if (!this.check()) return;
      if (this.isSubmit) return;
      this.isSubmit = true;
      this.$post2("user/api/passport/loginwithpwd", {
        username: this.count,
        type: this.loginType === "number" ? "phone " : "email",
        password: this.password,
      })
        .then((res) => {
          this.isSubmit = false;
          this.$saveLoginSession(res);
          this.$router.push({
            path: "/newHome",
            query: {
              ifhave: false,
            },
          });
        })
        .catch((e) => {
          this.isSubmit = false;
          this.showAlert(e);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.userLogin {
  .newTitleTwo {
    background: #fff;
  }
  /* @import "../../assets/css/puliceStyle.css"; */

  input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset !important;
  }

  .pageTitle {
    font-size: 0.48rem;
    font-weight: Bold;
    line-height: 0.78rem;
    margin-bottom: 1.2rem;
  }

  .text-1 {
    font-size: 0.24rem;
  }

  .text-1 span {
    color: #5b54ea;
  }

  .isShowPwd {
    display: inline-block;
    float: right;
    margin-top: 0.2rem;
    width: 0.34rem;
    height: 0.28rem;
  }

  .isShowPwd img {
    width: 100%;
    height: 100%;
  }

  .body1StyleInputStyle {
    border: 0px;
    font-size: 0.28rem;
    width: 80%;
    line-height: 0.8rem;
  }

  .bodyStyleDivStyle {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 0.1rem;
  }

  .body1Style {
    padding: 0.3rem;
  }

  .bottom-ve {
    width: 2.72rem;
    display: block;
    bottom: 0.2rem;
    padding-top: 1.2rem;
  }

  .pc_in {
    position: relative;
    float: left;
    width: 6.7rem;
    height: 1.12rem;
    border: none;
  }

  .pc_in .divbox {
    display: flex;
    width: 100%;
    height: 1.12rem;
  }

  .pc_in .divbox span {
    flex: 1;
    height: 100%;
    border-bottom: #e6e8ed solid 1px;
    border-right: none;
    margin: 0 0.16rem;
  }

  .pc_in .divbox span.bl {
    border-color: #000;
  }

  .userLogin {
    width: 100%;
    overflow: hidden;
  }

  .pc_in p {
    position: absolute;
    width: 120%;
    height: 1.12rem;
    line-height: 1.12rem;
    top: 0;
    left: 0;
    background: none;
    overflow: hidden;
  }

  .pc_in p input {
    float: left;
    width: 180%;
    height: 0.8rem;
    line-height: 0.8rem;
    margin-top: 0.2rem;
    letter-spacing: 0.84rem;
    background: none;
    text-indent: 0.4rem;
    font-size: 0.48rem;
  }
}
</style>